
.logo-wrapper {
  text-align: center;
  margin: 24px 0 12px 0;
  
  .logo {

    &-image {
      width: 25%;
      border-radius: 10px;
    }
  }
}

.vehicle-info-wrapper {
  display: flex;
  justify-content: center;
  padding: 5px;

  text-align: center;
  &-image {
    width: 20em;
    object-fit: cover;
  }
}

// react-player styling
.video-wrapper {
  width: auto; // Reset width
  height: auto; // Reset height
  padding: 1rem;
}

.react-player {
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative
}

@media only screen and (min-width: 601) {

  .login-details {
    height: .5em;
    width: 100%;
    // background-color: #0F75BC;
    background-color: green;
  }

  .login-main-page {
    display: flex;
  }

}

@media only screen and (max-width: 600px) {
  .wheres-my-vin-wrapper {
    display: flex;
    justify-content: center;
    padding: 5px;

    &-image {
      width: 20em;
      object-fit: cover;
    }
  }

  .logo-wrapper{
    .logo {
      width: 100%;
    
      &-image {
        width: 40%;
        height: auto;
      }
    }
    
    .registration {
      padding-top: 10px;
      font-size: large;
    }
    
    .login-body-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
    
    .login-main-page {
      position: absolute;
      background-color: #fff;
      border-radius: 30px 30px 0px 0px;
      padding: 30px;
      width: 100%;
      height: 100%;
      margin-top: -2em;
      z-index: 9;
    
      &-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
      }
    }
    
    .login-text {
      width: 100%;
      padding: 12px 12px;
      margin: 8px 0;
      box-sizing: border-box;
      border-radius: 10px;
    }
    
    .login-button {
      background-color: #0F75BC; /* Green */
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      border-radius: 15px;
      width: 100%;
    }
    
    .login-error {
      background-color: #fce4e4;
      border: 1px solid #fcc2c3;
      float: left;
      padding: 20px 30px;
      margin-bottom: 20px;
      border-radius: 10px;
      width: 100%;
    }
    
    .login-error-text {
      color: #cc0033;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      text-shadow: 1px 1px rgba(250,250,250,.3);
    }
  }
}

