.green-background {
  background-color: #00ce52;
}

.yellow-background {
  background-color: #e9c300;
}

.red-background {
  background-color: #d9304f;
}

.green-text {
  color: #00ce52
}

.yellow-text {
  color: #e9c300
}

.red-text {
  color: #d9304f;
}

.vehicle-worth {
  display: flex;
  flex-direction: column;

  &-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-header-tag {
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    font-weight: 700;
    color: #92d050;
    align-items: start;
  }

  &-header-powered-by {
    display: flex;
    flex-direction: row;
    color: #000;
    width: 100%;
    align-items: start;
    justify-content: start;
    width: 100%;

    img {
      width: 4rem;
    }
  }

  &-calculation {
    &-return {
      display: flex;
      justify-content: left;
      font-size: .75rem;

      a {
        text-decoration: none;
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      font-size: 1rem;
      font-weight: 700;
    }

    &-range {
      display: flex;
      justify-content: center;
      font-size: 2rem;
      font-weight: 700;
    }

    &-calculated {

      &-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 700;
        padding: 1rem 0 1rem 0;
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;
      }

      &-supply-and-days {
        display: flex;
        justify-content: center;
        flex-basis: 0;

        hr {
          width: 40px;
          margin: 0 auto;
          height: 3px;
          border-radius: 5px;
          background-color: #4472c4;
          opacity: 1;
          margin-bottom: 1rem;
        }
      }

      &-supply {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #4472c4;
        padding: .5rem;
        flex: 1;

        &-label {
          font-size: 1rem;
          font-weight: 700;
        }

        &-value {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 2rem;
          font-weight: 700;
          color: #4472c4;
        }

        &-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      &-days {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #4472c4;
        padding: .5rem;
        flex: 1;

        &-label {
          font-size: 1rem;
          font-weight: 700;
        }

        &-value {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 2rem;
          font-weight: 700;
          color: #4472c4;
        }

        &-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }


  &-value {
    display: flex;
    flex-direction: column;
    align-items: start;

    &-bottom-line {
      font-size: 1.25rem;
      font-weight: 700;
      // padding: 1rem;
      padding-top: 1.75rem;
      width: 100%;
    }

    &-range {
      color: #005aa0;
      font-size: 1.5rem;
      font-weight: 700;
    }

    &-eligibility {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 700;
      padding: 1rem;
    }

    &-bonus {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #92d050;
      background-color: #F8F8F8;
      font-size: 1.25rem;
      font-weight: 700;
      padding: 1rem;
      border-radius: 15px;

      &-dollar {
        width: 50px;
      }

      &-pay {
        padding-left: 1rem;
      }

      &-text {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-cta {
        background-color: #97D700;
        color: #fff;
        font-size: 1.50rem;
        padding: 1.25rem;
        border-radius: 15px;
        margin: 1.25rem;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      }
    }

    &-terms {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: .75rem;
    }

    &-calculated {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-size: .75rem;
      // padding: 1rem;
      padding: 1rem;

      a {
        padding-bottom: 1rem;
      }

      hr {
        width: 75%;
        margin: 0 auto;
        height: 2px;
        background-color: #4472c4;
        opacity: 1;
      }
    }
  }

  &-card {
    // border: 1px solid #000;
    margin-bottom: 1em;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

    &-title {
      font-size: larger;
      font-weight: 600;
      font-size: large;
      color: #fff;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }

    &-value {
      display: flex;
      flex-direction: column;

      &-range {
        text-align: center;
        font-weight: 700;
        font-size: larger;
        background-color: #edeceb;
      }

      &-action {
        text-align: center;
      }
    }

    &-info {
      text-align: center;
      padding: .25em;
    }
  }
}

.worth-page {
  padding: 3em 1em 1em 1em;

  &-form-action {
    text-align: center;

    & button {
      background-color: #0F75BC; /* Green */
      border: none;
      border-radius: 10px;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;;
    }
  }

  &-form-group {
    display: block;
    padding: .5em;

    & label {
      padding-bottom: 5px;
    }

    input[type=text] {
      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      box-sizing: border-box;
      border: 3px solid #ccc;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      outline: none;
    }

    input[type=text]:focus {
      border: 3px solid #555;
    }

    & input, label {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    & input {
      height: 45px;
      border: 1px solid #EBEBEB;
      border-radius: 15px;
      padding: 10px;
      font-weight: 400;
    }
  }
}