.service-records-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}

.service-record-records {
  margin: 10px;

  & img {
    border: 2px solid #C0C0C0;
    padding: 5px;
  }

  & img:hover {
    box-shadow: 5px 4px 10px #8B0000;
  }
}
