.records { 
  padding: 1em 1em 1em 1em;
}


.records-actions {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 15px;

  .btn {
    height: 3.5em;
    border-radius: 1em;
    font-size: 1.25em;
    font-weight: 600;
  }
}

.service-page {
  font-size: 1.25em;
  display: grid;
  grid-template-columns: (.25fr 1fr 1fr);
  gap: 10px;

  .col-one {
    grid-column: 1;
  }

  .col-two {
    grid-column: 2 / 4;
  }

  .col-three {
    grid-column: 1 / 4;
  }

  &-header {
    color: red;
  }

  &-body {
    font-size: 1em;
    text-align: center;
  }

  &-buttons {
    font-size: 1em;
  }
}