.account-page {
  padding: 3em 1em 1em 1em;

  &-form-action {
    text-align: center;

    & button {
      background-color: #0F75BC; /* Green */
      border: none;
      border-radius: 10px;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;;
    }
  }

  &-form-group {
    display: block;
    padding: .5em;

    & label {
      padding-bottom: 5px;
    }

    & input, label {
      display: block;
      width: 100%;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
    }

    & input {
      height: 45px;
      border: 1px solid #EBEBEB;
      border-radius: 15px;
      padding: 10px;
      font-weight: 400;
    }
  }
}