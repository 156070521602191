.body-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 5rem;
}

hr.warranty {
  border: 5px solid purple;
}

.button-image {
  width: 2rem;
}

.button-green {
  background-color: #94C83D;
  border: 1px solid #94C83D;
  width: 100%;
  height: 3.5em;
  border-radius: 10px;
  margin-top: 10px;

  &-icon {
    color: #fff;
  }

  &-text {
    padding-left: 10px;
    font-size: larger;
    font-weight: 600;
    color: #fff;
  }
}

.button-grey {
  background-color: #DAE1E8;
  border: 1px solid #DAE1E8;
  height: 3em;
  border-radius: 10px;
  margin-top: 10px;

  &-text {
    padding-left: 10px;
    font-size: larger;
    font-weight: 600;
  }
}

.button-text-black {
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  text-align: left;
  font-size: large;
  font-weight: 700;
  align-items: center;
}

.button-image-white {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.button-image-grey {
  background-color: #DAE1E8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
}

.logo-image {
  width: 60%;
  padding-bottom: .25em;
  padding-top: 1em;
}
.service-button {
  display: flex;
  border-radius: 15px;
  background-color: #DAE1E8;
  padding: 10px;

  &-vehicle-service-records {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;

    &-image {
      width: 2.5em;
    }

    &-text {
      padding: 10px 10px;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-warranty-service-contract {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5em;

    &-image {
      width: 2.5em;
    }

    &-text {
      padding: 10px 10px;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-certified-vehicle-program-info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5em;

    &-image {
      width: 2em;
    }

    &-text {
      padding: 10px 10px;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-online-vehicle-information {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5em;

    &-image {
      width: 2.5em;
    }

    &-text {
      padding: 10px 10px;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-ev-battery-health-report {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3em;

    &-image {
      width: 2em;
    }

    &-text {
      padding: 10px 10px;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-gear {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.5rem;
    height: 4.5rem;
    background-color: #fff;
    border-radius: 50%;

    &-image {
      width: 3.5em;
    }

    &-text {
      display: flex;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      text-align: left;
      font-size: large;
      font-weight: 700;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-key {
    display: flex;
    justify-content: center;
    width: 5.5rem;
    height: 4.5rem;
    background-color: #fff;
    border-radius: 50%;
  }

  &-truck {
    display: flex;
    justify-content: center;
    width: 5.5rem;
    height: 4.5rem;
    background-color: #fff;
    border-radius: 50%;

    &-select {
      background-color: #DAE1E8;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.5rem;
      height: 5.5rem;

      border-radius: 50%;
    }

    &-image {
      width: 3em;
    }

    &-text {
      display: flex;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      text-align: left;
      font-size: large;
      font-weight: 700;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-key {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5.5rem;
    height: 4.5rem;
    background-color: #fff;
    border-radius: 50%;

    &-image {
      width: 2.75rem;
    }

    &-text {
      display: flex;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      text-align: left;
      font-size: large;
      font-weight: 700;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-locked-car {
    display: flex;
    justify-content: center;
    width: 5.5rem;
    height: 4.5rem;
    background-color: #fff;
    border-radius: 50%;

    &-image {
      width: 3em;
    }

    &-text {
      display: flex;
      width: 100%;
      height: 100%;
      padding-left: 20px;
      text-align: left;
      font-size: large;
      font-weight: 700;
      align-items: center;
    }

    &-right {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.login-details {
  padding: 20px;
  height: 8em;
  width: 100%;
  background-color: #0F75BC;
}

.main-page {
  display: block;
  background-color: #fff;
  border-radius: 30px 30px 0px 0px;
  width: 100%;
  height: 100%;
}

.dealer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  img {
    max-width: 50%; /* Ensures the image doesn't overflow the container */
    max-height: 50%; /* Ensures the image respects the container height */
    width: auto; /* Maintain the aspect ratio */
    height: auto; /* Maintain the aspect ratio */
  }
}

#container-row {
  height: 150px;
  position: relative;
  // margin: 30px;
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: #0057e3;
}

.overlay {
  z-index: 9;
  margin: 30px;
  background: #009938;
}

.container-navbar {
  display: grid;
  grid-template-columns: 80% 20%;
  align-items: center;
  justify-content: left;
  padding: 0px 20px 10px 10px;
  position:sticky;
  background-color: #fff;
  top: 0;
  z-index: 999;

  &-logout-button {
    border: 2px solid #CECCCF ;
    color: #CECCCF;
    width: 5rem;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 40px;
    font-size: large;
    font-weight: 500;
  }
}

.login-container-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.menu-select {
  padding-top: .5em;
}

.record-page {
  display: flex;
  flex-direction: column;

  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
  }

  &-bold {
    font-weight: bold;
  }

  &-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
      max-width: 50%; /* Ensures the image doesn't overflow the container */
      max-height: 50%; /* Ensures the image respects the container height */
      width: auto; /* Maintain the aspect ratio */
      height: auto; /* Maintain the aspect ratio */
    }
  }

  .record-page-certified-vehicle {
    padding: 0px 10px 0px 10px;
    background-image: url(images/service-info-copy.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  &-certified-vehicle-header {
    display: grid;
    grid-template-columns: 10% 90%;
    padding: 10px;
  }

  &-section-header {
    padding: 0px 5px 15px 0px;

    &-title {
      font-size: larger;
      font-weight: 700;
      color: #0F75BC;

      &-white {
        color: white;
        font-size: larger;
        font-weight: 700;
      }
    }
  }

  &-section-body-white {
    color: #fff;
    padding: 10px;
  }

  &-good-news {
    border-top: solid 1px gray;
    padding-top: 2em;

    &-title {
      font-size: larger;
      font-weight: 700;
      color: #0F75BC;
    }

    &-sub-title {
      font-weight: 700;
    }
  }

  &-warranty {
    width: 2em;
  }

  &-header {
    display: flex;
    align-items: center;
    column-gap: 12rem;

    &-text {
      color: black;
      font-weight: 700;
      font-size: larger;
      padding-left: 10px;
    }

    &-warranty {
      background-color: #DAE1E8;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.5rem;
      height: .5rem;

      border-radius: 50%;
    }
  }

  &-service-circle-check {
    &-header {
      align-content: center;
      padding-left: 10px;
    }

    width: 100%;
  }

  &-service-info-image {
    width: 100%;
  }

  &-service-info-body {
    display: flex;
    align-items: center;
  }

  &-body {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: medium;
    text-align: left;

    &-column {
      padding-left: 0px;

      &-header {
        font-size: 1.5em;
        font-weight: 700;
      }
    }
  }

  &-sub-info {
    display: flex;

    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .75em;
      padding-left: 15px;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;

    &-button-red {
      border: 2px solid black;
      background-color: #DA283D;
      color: white;
      width: 100%;
      height: 3em;
      border-radius: 10px;
      margin-top: 10px;
      align-content: center;
    }

    &-button-default {
      background-color: white;
      height: 3em;
      border-radius: 10px;
      margin-top: 10px;
    }

    &-button-text {
      padding-left: 10px;
    }
  }

  &-footer {
    font-size: small;
    color: #88818D;
    padding-top: 25px;
  }
}

.service-page {
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
  }

  &-bold {
    font-weight: bold;
  }

  &-warranty {
    width: 2em;
  }

  &-header {
    display: flex;
    align-items: center;

    &-gear-select {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.5rem;
      height: 3.5rem;

      border-radius: 50%;
    }

    &-gear {

      background-color: #DAE1E8;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 6.5rem;
      height: 4.5rem;

      border-radius: 50%;
    }

    &-schedule {
      background-color: #DAE1E8;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4.5rem;
      height: 4.5rem;

      border-radius: 50%;
    }

    &-text {
      color: black;
      font-weight: 600;
      width: 20rem;
      padding-left: 10px;
    }
  }

  &-body {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: medium;
    text-align: left;
  }

  &-sub-info {
    display: flex;

    &-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .75em;
      padding-left: 15px;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;

    &-button-red {
      background-color: #94C83D;
      border: 1px solid #94C83D;
      color: white;
      width: 100%;
      height: 3.5em;
      border-radius: 10px;
      margin-top: 10px;
      align-content: center;
    }

    &-button-default {
      background-color: #DAE1E8;
      border: 1px solid #DAE1E8;
      height: 3em;
      border-radius: 10px;
      margin-top: 10px;
    }

    &-button-text {
      padding-left: 10px;
      font-size: larger;
      font-weight: 600;
    }
  }

  &-footer {
    font-size: small;
    color: #88818D;
    padding-top: 25px;
  }
}

.service-page-buttons-button red {
  background-color: red;
}

.menu-container {
  position: fixed;
  left: -300px;
  top: 0;
  bottom: 0;
  width: 300px;
  background: white;
  transition: left 0.3s ease-out;
}

#menu-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

#menu-button:active + .menu-container {
  left: 0;
}

.bottom-nav {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  background-color: white;
  z-index: 3000;
  height: 64px;
  border-top: 1px solid #ebebeb;
}

.bottom-nav-item {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all 0.3s ease;
}

.bottom-nav-item.active {
  background-color: #0F75BC;
  color: white;
}

.bottom-nav-item--title {
  margin-top: 5px;
  font-size: 10.3px;
  margin-bottom: 0px;
}