.vehicle-details {
  padding: 10px 20px 20px 20px;
  color: #fff;
  font-size: larger;
  font-weight: 600;
  // background-color: #0F75BC;
  background-image: linear-gradient(to right, #93D500, #0075C9);
  height: 10em;
  margin-bottom: -2em;

  width: 100%;
  left: 0;

  &-button {
    border: none;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
  }

  &-body {
    display: flex;

    ul {
      list-style-type: none;
    }

    li {
      padding: 1.5px;
    }

    &-text {
      font-size: small;
    }

    &-button {
      button {
        border-radius: 10px;
        background-color: #1D82C8;
        color: #fff;
        font-size: small;
        border: solid 1px #fff;
      }
    }

    &-image-container {
      position: relative;
      // display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-image-container::after {
      content: '+';
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 1.75em;
    }

    &-image {
      // width: 7em;
      width: 130px;
      height: 100px;
      border-radius: 10px;
      object-fit: cover;
    }

    &-image-text {
      position: absolute;
      // bottom: 0;
      // right: 0;
      background-color: rgba(255, 255, 255, 0.5);
      padding: 5px;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      color: #000;
    }
  }

}