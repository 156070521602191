.hidden {
  display: none !important;
  opacity: 0;
  transition: opacity 300ms;
}

.form {
  box-sizing: border-box;
  padding-bottom: 1em;
}

.form label {
  font-weight: bold;
}

.form input {
  font-size: 1.3rem;
  width: 100%;
  padding: 12px 12px;
  border-radius: 10px;    
}

.form button {
  background-color: #0F75BC; /* Green */
  font-size: 1em;
  border: none;
  color: white;
  padding: 9px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 15px;
  width: 100%;
}

.form button {
  margin-top: 5px;
}

#data-capture-view {
  height: 100%;
  width: 100%;
}

.result-row .symbology {
  margin-top: 8px;
  font-size: 0.9em;
  color: #2ec1ce;
}

#modal {
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 300ms;
}

#modal.open {
  opacity: 1;
}

@supports (-webkit-touch-callout: none) {
  #modal {
    height: -webkit-fill-available;
  }
}

#modal .barcode-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: -1;
}

#modal .modal-inner {
  padding: 24px;
  width: 0;
  height: 0;
  background-color: white;
  box-shadow: 3px 4px 12px 4px #919191;
  z-index: 2;
  transition: all 300ms;
}

#modal.open .modal-inner {
  width: 80vw;
  height: 80vh;
}

@media only screen and (max-width: 900px) {
  #modal.open .modal-inner {
    padding: 12px;
  }
}