/* Style for the modal background */
.vehicle-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    position: relative;
    z-index: 100;
    background-color: white;
    color: #000;
    border-radius: 5px;
    padding: 20px;
    max-width: 500px;
    height: 70%;
    width: 90%;
    overflow: auto;
  }

  &-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
}

/* Style for the modal content */
// .modal-content {
  
// }

/* Style for the close button */
// .close-button {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background-color: transparent;
//   border: none;
//   font-size: 1.5rem;
//   cursor: pointer;
// }
